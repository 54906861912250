.card {
  padding: 1rem;
  max-height: 500px;
}

.main {
  margin: 0 auto;
  display: grid;
  grid-gap: 100px;
}

.main h1 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #00006d;
}
.main h3 {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #4c5d77;
}

.main h4 {
  text-align: center;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  padding: 0 60px;
  margin-bottom: 27px;
}
@media (min-width: 1200px) {
  .main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1199px) {
  .main {
    grid-gap: 10px;
  }
  .card {
    height: auto;
  }
  .main h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .main h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

.bullets ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 15px;
}

.bullets li {
  padding-left: 1em;
  text-indent: -1em;
  font-family: Bitter, serif;
  font-size: 1.125rem;
  font-weight: 700;
}

.bullets li:before {
  content: '✅';
  padding-right: 20px;
}
