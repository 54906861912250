@import url(https://fonts.googleapis.com/css2?family=Public+Sans);
@import url(https://fonts.googleapis.com/css2?family=Merriweather);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html, body {
  height: 100%;
}

.Right {
  width: 30%;
  position: absolute;
  right: 0px;
  top: 50px;
  bottom: 50px;
  background-color: rgba(12, 12, 12, 0.2);
  box-shadow: 0 0 10px 10px white inset;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: fadeout 2.5s;
}

.Left {
  width: 30%;
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 50px;
  background-color: rgba(12, 12, 12, 0.2);
  box-shadow: 0 0 10px 10px white inset;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: fadeout 2.5s;
}

.Right:active {
  opacity: 0.4;
  animation: fadein 0.9s;
}

.Left:active {
  opacity: 0.4;
  animation: fadein 0.9s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

.Document {
  position: relative;
}

.PageInfo {
  text-align: center;
  margin: 0;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

@media screen and (min-width: 992px) {
  .logo {
    height: 60px;
    background-image: url(/static/media/pixelLogo.e837a5a7.png);
    background-size: cover;
    margin: 10px;
  }
  .col {
    width: 50%;
  }
  .col2 {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .logo {
    height: 100px;
    background: rgba(255, 255, 255, 0.2);
    background-size: cover;
    margin: 10px;
  }
  .col {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
}

.dropContainer {
  margin: 15px;
}

@media screen and (orientation: portrait) {
  #menuList {
    width: 100vw;
    min-height: 100vh;
    padding: 5% 10% 5% 10%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .pixelLogo {
    width: 35%;
    align-self: center;
  }
  .menuButton {
    min-height: 60px;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .menuInfo {
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .menuList {
    min-height: 70vh;
    margin-top: 40px;
  }
  .menuCard {
    border-radius: 10px;
    width: 50%;
    height: 150px;
    background-image: url(/static/media/wineList.80e8eb88.jpg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .menuCard2 {
    border-radius: 10px;
    width: 50%;
    height: 150px;
    background-image: url(/static/media/breakfast.b5b29943.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (orientation: landscape) {
  #menuList {
    width: 100vw;
    background-color: #f1f1f1;
    min-height: 100vh;
    padding: 3% 10% 0 10%;
    display: flex;
    flex-direction: column;
  }
  .pixelLogo {
    width: 15%;
    align-self: center;
  }
  .menuButton {
    width: 49%;
    min-height: 70px;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .menuInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menuList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.menuIconContainer {
  display: flex;
  align-items: center;
  width: 20%;
  text-align: center;
  justify-content: center;
}

#menuButtonTitle {
  font-size: 16px;
  color: #3a3a3c;
  margin: 0;
}

.restaurantTitle {
  color: #3a3a3c;
  margin: 0;
}

.menuTitle {
  display: flex;
  margin: 10px 15px 0px 15px;
  text-align: center;
}

.menuTitle .backIcon {
  width: 25px;
}

.menuName {
  width: 100%;
  margin-right: 25px;
}

.pixelBtn {
  text-align: center;
  margin-bottom: 20px;
}

.pixelBtn1 {
  margin-top: 10px;
  text-align: center;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.documentButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.MenuContainer {
  background-color: white;
  height: auto;
  width: 100%;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

.card {
  padding: 1rem;
  max-height: 500px;
}

.main {
  margin: 0 auto;
  display: grid;
  grid-gap: 100px;
}

.main h1 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #00006d;
}
.main h3 {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #4c5d77;
}

.main h4 {
  text-align: center;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  padding: 0 60px;
  margin-bottom: 27px;
}
@media (min-width: 1200px) {
  .main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1199px) {
  .main {
    grid-gap: 10px;
  }
  .card {
    height: auto;
  }
  .main h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .main h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

.bullets ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 15px;
}

.bullets li {
  padding-left: 1em;
  text-indent: -1em;
  font-family: Bitter, serif;
  font-size: 1.125rem;
  font-weight: 700;
}

.bullets li:before {
  content: '✅';
  padding-right: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

