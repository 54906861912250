#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

@media screen and (min-width: 992px) {
  .logo {
    height: 60px;
    background-image: url(../styles/pixelLogo.png);
    background-size: cover;
    margin: 10px;
  }
  .col {
    width: 50%;
  }
  .col2 {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .logo {
    height: 100px;
    background: rgba(255, 255, 255, 0.2);
    background-size: cover;
    margin: 10px;
  }
  .col {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
}

.dropContainer {
  margin: 15px;
}
