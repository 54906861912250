@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@media screen and (orientation: portrait) {
  #menuList {
    width: 100vw;
    min-height: 100vh;
    padding: 5% 10% 5% 10%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .pixelLogo {
    width: 35%;
    align-self: center;
  }
  .menuButton {
    min-height: 60px;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .menuInfo {
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .menuList {
    min-height: 70vh;
    margin-top: 40px;
  }
  .menuCard {
    border-radius: 10px;
    width: 50%;
    height: 150px;
    background-image: url('../styles/wineList.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .menuCard2 {
    border-radius: 10px;
    width: 50%;
    height: 150px;
    background-image: url('../styles/breakfast.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (orientation: landscape) {
  #menuList {
    width: 100vw;
    background-color: #f1f1f1;
    min-height: 100vh;
    padding: 3% 10% 0 10%;
    display: flex;
    flex-direction: column;
  }
  .pixelLogo {
    width: 15%;
    align-self: center;
  }
  .menuButton {
    width: 49%;
    min-height: 70px;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .menuInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menuList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.menuIconContainer {
  display: flex;
  align-items: center;
  width: 20%;
  text-align: center;
  justify-content: center;
}

#menuButtonTitle {
  font-size: 16px;
  color: #3a3a3c;
  margin: 0;
}

.restaurantTitle {
  color: #3a3a3c;
  margin: 0;
}

.menuTitle {
  display: flex;
  margin: 10px 15px 0px 15px;
  text-align: center;
}

.menuTitle .backIcon {
  width: 25px;
}

.menuName {
  width: 100%;
  margin-right: 25px;
}

.pixelBtn {
  text-align: center;
  margin-bottom: 20px;
}

.pixelBtn1 {
  margin-top: 10px;
  text-align: center;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.documentButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.MenuContainer {
  background-color: white;
  height: auto;
  width: 100%;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
