.Right {
  width: 30%;
  position: absolute;
  right: 0px;
  top: 50px;
  bottom: 50px;
  background-color: rgba(12, 12, 12, 0.2);
  box-shadow: 0 0 10px 10px white inset;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  -webkit-animation: fadeout 2.5s;
  -moz-animation: fadeout 2.5s;
  -ms-animation: fadeout 2.5s;
  -o-animation: fadeout 2.5s;
  animation: fadeout 2.5s;
}

.Left {
  width: 30%;
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 50px;
  background-color: rgba(12, 12, 12, 0.2);
  box-shadow: 0 0 10px 10px white inset;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  -webkit-animation: fadeout 2.5s;
  -moz-animation: fadeout 2.5s;
  -ms-animation: fadeout 2.5s;
  -o-animation: fadeout 2.5s;
  animation: fadeout 2.5s;
}

.Right:active {
  opacity: 0.4;
  -webkit-animation: fadein 0.9s;
  -moz-animation: fadein 0.9s;
  -ms-animation: fadein 0.9s;
  -o-animation: fadein 0.9s;
  animation: fadein 0.9s;
}

.Left:active {
  opacity: 0.4;
  -webkit-animation: fadein 0.9s;
  -moz-animation: fadein 0.9s;
  -ms-animation: fadein 0.9s;
  -o-animation: fadein 0.9s;
  animation: fadein 0.9s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

.Document {
  position: relative;
}

.PageInfo {
  text-align: center;
  margin: 0;
}
